import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Scene from "./Scene"; // Assuming this is where your Scene component is located
import ButtonAppBar from "./ButtonAppBar";
import Home from "./Home";
import "./App.css";

const App = () => {
  return (
    <>
      <ButtonAppBar />
      <Scene />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
