import React, { useRef } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Stars } from "@react-three/drei";
import Home from "./Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Model = () => {
  const divRef = useRef();
  const modelRef = useRef();
  const gltf = useGLTF("models/untitled.glb");
  const gltfjs = useGLTF("models/js.glb");

  useFrame((_, delta) => {
    if (modelRef.current) {
      // Rotate the model around its y-axis
      modelRef.current.rotation.y += 0.1 * delta;

      // Update the position of the stars relative to the model
      modelRef.current.children.forEach((child) => {
        if (child.name === "stars") {
          child.rotation.y += 0.01 * delta;
        }
      });

      // Rotate the GLB object in the opposite direction
      modelRef.current.children.forEach((child) => {
        if (child.name === "glbObject") {
          child.rotation.y -= 0.01 * delta;
        }
      });
    }
  });

  return (
    <>
      <group ref={modelRef}>
        <primitive object={gltfjs.scene} />
        <primitive object={gltf.scene} />
        <ambientLight intensity={0.1} />
        {/* Your 3D model */}
        {/* Assuming the stars and GLB object are part of the model */}
        <Stars
          position={[0, 0, 0]}
          radius={10} // Adjust the radius of the skybox
          depth={50} // Adjust the depth of the skybox
          count={2584} // Adjust the number of stars
          factor={1} // Adjust the size of the stars
          saturation={1} // Set saturation to 0 for grayscale stars
        />

        <pointLight
          color="whitesmoke"
          intensity={0.5}
          position={[0, -13, -25]}
        />

        <pointLight
          color="whitesmoke"
          intensity={0.5}
          position={[0, -13, 25]}
        />
        <primitive object={gltf.scene} />
      </group>

      {/* <group>
        <Html
          position={[0, -10, -10]}
          scaleFactor={10}
          style={{ textAlign: "center" }}
        >
          <div
            ref={divRef}
            style={{
              width: "100%",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <KeyboardArrowDownIcon sx={{ width: "80px", height: "80px" }} />
          </div>
        </Html>
      </group> */}
    </>
  );
};

export default Model;
