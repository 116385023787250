import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import Model from "./Model"; // Assuming this component renders your 3D model

const FallbackUI = () => {
  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]} castShadow>
      <sphereGeometry attach="geometry" args={[1, 16, 16]} />
      <meshStandardMaterial
        attach="material"
        color="orange"
        transparent
        opacity={0.5}
        roughness={0.1}
        metalness={0.1}
      />
    </mesh>
  );
};

const Scene = () => {
  const modelRef = useRef();
  return (
    <div
      style={{
        background: "rgb(2,0,36)",
        background:
          "linear-gradient(62deg, rgba(2,0,36,0.908000700280112) 25%, rgb(31 1 91 / 93%) 50%, rgba(2,0,36,0.908000700280112) 75%)",
        width: "100%",
        height: "100vh",
      }}
    >
      <Canvas style={{ position: "absolute" }}>
        <Suspense fallback={<FallbackUI />}>
          <Model ref={modelRef} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Scene;
