import * as React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

export default function ButtonAppBar() {
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        width: "100%",
        position: "absolute",
        zIndex: "2",
      }}
    >
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center",
          backgroundColor: " transparent",
        }}
      >
        <div
          style={{
            display: "grid",
            paddingLeft: "21px",
            paddingRight: "21px",
            backgroundColor: "#494646",
            color: "whitesmoke",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              justifySelf: "center",
              alignSelf: "center",
              fontSize: "13px",
              fontFamily: "'Silkscreen', sans-serif",
              fontStyle: "normal",
            }}
          >
            Web developer
          </Typography>
        </div>
        <div className="triangulo-equilatero-top-left "></div>
      </div>

      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center",
          backgroundColor: " transparent",
        }}
      >
        <div className="triangulo-equilatero-top-right "></div>
        <div
          style={{
            display: "grid",

            justifyContent: "center",
            alignItems: "center",
            padding: "21px",
            backgroundColor: "#494646",
            gridAutoFlow: "column",
          }}
        >
          <a href="https://gitlab.com/dacost.dev">
            <IconButton>
              <GitHubIcon sx={{ color: "whitesmoke" }} />
            </IconButton>
          </a>
          {/* <IconButton>
            <EmailIcon sx={{ color: "whitesmoke" }} />
          </IconButton> */}
        </div>
      </div>
    </div>
  );
}
