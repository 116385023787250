import React from "react";
import Cards from "./Cards";
import "./App.css";

const Home = () => {
  return (
    <>
      <div className="cardBackground">
        <h1
          style={{
            color: "whitesmoke",
            fontSize: "21px",
            fontFamily: "'Silkscreen', sans-serif",
            fontStyle: "normal",
          }}
        >
          Overview
        </h1>
        <div
          className="cardsCotatainer"
          style={{
            color: "whitesmoke",
            textAlign: "center",
            paddingTop: "21px",
          }}
        >
          <p>
            Full Stack Web Developer with expertise in developing, designing,
            enhancing, testing, and creating web applications for diverse
            industries across multiple countries in the Americas.
          </p>

          <Cards />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center",
          gap: "21px",
          backgroundColor: "#252424",
          color: "whitesmoke",
        }}
      >
        <div style={{ maxWidth: "300px" }}>
          {" "}
          All rights reserved. © dacost.dev 2024{" "}
        </div>
      </div>
    </>
  );
};

export default Home;
