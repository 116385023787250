import React from "react";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Languages",
    },
  },
};

export const options2 = {
  responsive: true,
  height: "300px",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Frameworks and Libraries",
    },
  },
};

const labels = [
  "Javascript",
  "Typescript",
  "Python",
  "PHP",
  "HTML",
  "CSS",
  "GraphQL",
  "SQL",
];
const labelsLib = [
  "React.js",
  "Angular",
  "Codeigniter",
  "Django",
  "Fast Api",
  "Node.js",
  "React-Native",
  "Expo",
  "Next.js",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Years",
      data: [7, 4, 4, 1, 7, 7, 4, 4],

      backgroundColor: ["rgba(75, 192, 192, 0.8)"],
    },
  ],
};

export const data2 = {
  labels: labelsLib,
  datasets: [
    {
      label: "Years",
      data: [4, 3, 1, 4, 2, 4, 1, 1, 1],
      backgroundColor: ["rgba(153, 102, 255, 0.8)"],
    },
  ],
};

const Cards = () => {
  return (
    <div className="cardsCotatainer">
      <hr className="hrLine" />
      <div>
        <BarChart data={data} options={options} />
        <BarChart data={data2} options={options2} />
      </div>
      <hr className="hrLine" />
      <div>
        <DoughnutChart />
      </div>
    </div>
  );
};

export default Cards;
